import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackgroundImage from "gatsby-background-image";
import Image from "gatsby-image";
import React from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import CarouselControl from "../components/carouselControl";
import Label from "../components/label";
import { Contacts, References, Teaser } from "../components/sidebar";
import { carouselBreakpoints } from "../helper/carousel";
import { formatDate } from "../helper/date";
import { getFilteredSearchLink } from "../helper/link";
import { dtrOptions } from "../helper/richText";

const Article = props => {
  const article = props;

  const [showLightbox, setShowLightbox] = React.useState(false);
  const [imageToShow, setImageToShow] = React.useState(0);

  const showImage = imageIndex => {
    setImageToShow(imageIndex);
    setShowLightbox(true);
  };

  const responsive = {
    l: {
      breakpoint: carouselBreakpoints.l,
      items: 2,
    },
    m: {
      breakpoint: carouselBreakpoints.m,
      items: 2,
    },
    s: {
      breakpoint: carouselBreakpoints.s,
      items: 2,
    },
    xs: {
      breakpoint: carouselBreakpoints.xs,
      items: 1,
    },
  };

  const tags = [];
  if (article && article.focusOfWork) {
    tags.push(article.focusOfWork);
  }
  if (article && article.topic) {
    tags.push(...article.topic);
  }
  if (article && article.organization) {
    tags.push(...article.organization);
  }

  return (
    <>
      {article && article.headerImage && (
        <BackgroundImage
          Tag="section"
          className={`pageHeader`}
          fluid={[{ src: article.headerImage.image.file.url }]}
        ></BackgroundImage>
      )}
      <Container fluid className="articleDetail">
        <Row>
          <Col lg={4} className="order-2">
            {article && (
              <>
                {article.authorOfPublication && (
                  <Contacts
                    authors={article.authorOfPublication.map(author => ({
                      ...author,
                      image: author.photo &&
                        author.photo.image && {
                          src: author.photo.image.file.url,
                        },
                      bio: author.biography && author.biography.biography,
                    }))}
                    locale={article.node_locale}
                  />
                )}
                {article.additionalInfo &&
                  article.additionalInfo.map(info => (
                    <Teaser
                      headline={info.headline}
                      content={documentToReactComponents(info.body, dtrOptions)}
                      link={info.link}
                      locale={article.node_locale}
                    />
                  ))}
                {article.references && (
                  <References
                    references={article.references.map(reference => ({
                      ...reference,
                      metadescription:
                        reference.metadescription &&
                        reference.metadescription.content[0].content[0].value,
                    }))}
                    locale={article.node_locale}
                  />
                )}
              </>
            )}
          </Col>
          <Col lg={8} className="order-1">
            <h2 className="title">{article && article.articleTitle}</h2>
            <h5 className="subtitle">{article && article.articleSubtitle}</h5>
            <div className="publication-date">
              {article && formatDate(article.postDateTime, article.locale)}
            </div>
            <div className="labels">
              {tags
                .filter(item => !!item)
                .map(item => (
                  <Label
                    text={item.name}
                    type="secondary"
                    className="label"
                    link={getFilteredSearchLink(
                      item.contentful_id,
                      article.node_locale
                    )}
                  />
                ))}
            </div>
            {article && article.articleImages && (
              <Image
                className="article-image"
                fluid={[{ src: article.articleImages[0].image.file.url }]}
              ></Image>
            )}
            {article &&
              documentToReactComponents(article.articleReader, dtrOptions)}
            {article &&
              documentToReactComponents(article.articleContent, dtrOptions)}
            {article && article.articleImages && (
              <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                containerClass="frient-carousel article-gallery"
                customButtonGroup={<CarouselControl />}
                arrows={false}
                dots={false}
                dotListClass="frient-carousel-dots"
              >
                {article.articleImages.map((image, index) => (
                  <Col xs={12} onClick={() => showImage(index)}>
                    <BackgroundImage
                      className={"article-gallery-image"}
                      fluid={[{ src: image.image.file.url }]}
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="lg"
                        className="article-gallery-image-zoom-icon"
                      />
                    </BackgroundImage>
                  </Col>
                ))}
              </Carousel>
            )}
          </Col>
        </Row>
      </Container>
      {showLightbox ? (
        <Lightbox
          allowZoom={false}
          allowRotate={false}
          allowReset={false}
          startIndex={imageToShow}
          showTitle={false}
          onClose={() => setShowLightbox(false)}
          images={article.articleImages.map(image => image.image.file.url)}
        />
      ) : null}
    </>
  );
};

export default Article;
