import Image from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Link from "../helper/link";

const IconCards = ({ iconCards, hideTextOnMobile, hideText }) => {
  const cards = iconCards.map(iconCard => {
    const link = iconCard.link
      ? iconCard.link.slug
      : { url: iconCard.externalLink };
    return (
      <Col xs={6} sm={6} md={4} lg={3} xl={3}>
        <Link
          to={link}
          className={`icon-card ${hideTextOnMobile ? "hideTextOnMobile" : ""} ${hideText ? "hideText" : ''}`}
        >
          <div className="icon-card-inner">
            <div className="icon-card-content">
              <div className="top">
                <Image
                  className="icon d-block w-100"
                  fluid={iconCard.icon.fluid}
                />

              </div>
              {!hideText &&
                (
                  <div className="bottom">
                    <h6 className="title">{iconCard.title}</h6>
                  </div>
                )}
            </div>
          </div>
        </Link>
      </Col>
    );
  });
  return (
    <Container className="icon-cards p-0 pb-5">
      <Row className="d-flex justify-content-center">{cards}</Row>
    </Container>
  );
};

/* PropTypes */
IconCards.propTypes = {
  iconCards: PropTypes.array,
  hideTextOnMobile: PropTypes.bool,
  hideText: PropTypes.bool
};
IconCards.defaultProps = {
  iconCards: [],
  hideTextOnMobile: false,
  hideText: false,
};

export default IconCards;
