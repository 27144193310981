import React from "react";
import PropTypes from "prop-types";
import WorkArea from "./workArea";

const WorkAreasWrapper = ({ items, itemsPerRow, node_locale }) => (
  <section className="workAreasWrapper" style={{'--workAreasItems': itemsPerRow}}>
    {items.map(item => <WorkArea {...{...item, node_locale}}/>)}
  </section>
);

WorkAreasWrapper.propTypes = {
  items: PropTypes.array,
  itemsPerRow: PropTypes.string
};
WorkAreasWrapper.defaultProps = {
  items: [],
};

export default WorkAreasWrapper;
