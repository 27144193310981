import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const PageTitle = ({ title, subtitle, leftAligned }) => (
  <Container fluid className="pageTitle">
    <Row className={getContainerClasses(leftAligned)}>
      <Col md={8} lg={6}>
        <h1 className={getContentClasses(leftAligned)}>{title}</h1>
        {subtitle && subtitle.content && (
          <p className={getContentClasses(leftAligned)}>
            {subtitle.content[0].content[0].value}
          </p>
        )}
      </Col>
    </Row>
  </Container>
);

const getContainerClasses = leftAligned => {
  const classes = ["d-flex", "align-items-center", "justify-content-center"];
  if (leftAligned) {
    classes.push("justify-content-md-start");
  }
  return classes.join(" ");
};

const getContentClasses = leftAligned => {
  const classes = ["text-center"];
  if (leftAligned) {
    classes.push("text-md-left");
  }
  return classes.join(" ");
};

/* PropTypes */
PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
PageTitle.defaultProps = {};

export default PageTitle;
