import React from "react";
import PropTypes from "prop-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Link, { getLocalizedLink } from "../helper/link";

const WorkArea = ({ headline, headlineColor, image, link, backgroundColor, node_locale }) => {
  return (
  <Link 
    to={getLocalizedLink(
      `/${link.slug}`,
      node_locale
    )} 
    className="workArea" 
    style={{backgroundColor: backgroundColor, color: headlineColor, backgroundImage: `url(${image?.image?.fluid?.src})`}}
  > 
    <div className="headline">
      {documentToReactComponents(headline.json)}
    </div>
  </Link>
)};


WorkArea.propTypes = {
  headline: PropTypes.shape({
    headline: PropTypes.string
  }),
  headlineColor: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.object,
  backgroundColor: PropTypes.string
};
WorkArea.defaultProps = {
  headlineColor: '#141d50',
  backgroundColor: '#c1c2d8'
};

export default WorkArea;
