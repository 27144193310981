/* Global imports */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { dtrOptions } from "../helper/richText";

/* Local imports */

/* Component definition */
const FreeText = ({ body, title, hideTitle }) => {
  return (
    <article className="freeText">
      <Row>
        <Col>
          {!hideTitle ? <h2>{title}</h2> : ""}
          {documentToReactComponents(body.json, dtrOptions)}
        </Col>
      </Row>
    </article>
  );
};
/* PropTypes */
FreeText.propTypes = {};
FreeText.defaultProps = {};

/* Local utility functions */

/* Styles */

export default FreeText;
