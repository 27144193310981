import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import Checkbox from "./checkbox";
import { getValue } from "../helper/translation";
import MicrocopyContext from "../context/microcopy/microcopyContext";
import RegistrationContext from "../context/registration/registrationContext";
import Input from "./input";
import Select from "./select";
import Link from "../helper/link";

const EventRegistration = ({
  eventTitle,
  welcomeText,
  formHeadline,
  privacyPolicyHeadline,
  agreeWithPrivacyPolicySublineText,
  pdfAsset,
  privacyPolicyText,
  checkboxAgreeWithPrivacyPolicy,
  checkboxConsentPhotos,
  customCheckbox1Text,
  customCheckbox2Text,
  customCheckbox3Text,
  contactHeadline,
  contactText,
}) => {
  const microcopy = useContext(MicrocopyContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("")
  const [formValues, setFormValues] = useState({
    title: "",
    emailAddress: "",
    academicTitle: "",
    givenName: "",
    familyName: "",
    company: "",
    job: "",
    streetAddress: "",
    postalCode: "",
    city: "",
    privacyPolicy: false,
    consentPhotos: false,
    customCheckbox1: false,
    customCheckbox2: false,
    customCheckbox3: false,
    eventTitle: "",
  });
  const [titleValue, setTitleValue] = useState({})
  const [formDataSent, setFormDataSent] = useState(false)
  const setShowConfirmation = useContext(RegistrationContext)
  const requiredFields = ["title", "givenName", "familyName", "emailAddress", "company"]

  useEffect(() => {
    if (eventTitle) {
      setFormValues(prevState => ({
        ...prevState,
        eventTitle 
      }))
    }
  }, [eventTitle])

  const handleFormValues = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleCheckboxes = (e) => {
    setFormValues({ ...formValues, [e.target.name]: !formValues[e.target.name] });
  }

  const getFormData = object => Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

  const sendDataToSpreadSheet = () => {
    const scriptURL = process.env.GATSBY_GOOGLE_SPREADSHEET_URL
    fetch(scriptURL, { method: 'POST', body: getFormData({...formValues})})
      .then(response => {
        setFormDataSent(true)
        setShowConfirmation(true)
      })
      .catch(error => setErrorMessage(getValue("error.sendingForm", microcopy)))
  }

  const customCheckboxes = [customCheckbox1Text,customCheckbox2Text,customCheckbox3Text].some(customCheckbox => customCheckbox)
  const handleSubmit = e => {
    e.preventDefault();
    if (!isEmailValid(formValues["emailAddress"])) {
      setErrorMessage(getValue("error.insertValidEmail", microcopy));
    } else if (formValues["city"] !== "" && !formValues["city"].match(/^[\u00C0-\u017Fa-zA-Z'][\u00C0-\u017Fa-zA-Z-' ]+[\u00C0-\u017Fa-zA-Z']?$/)) { // Only letters allowed on city
      setErrorMessage(getValue("error.insertOnlyLetters", microcopy));
    } else if (customCheckboxes && formValues["customCheckbox1"] === false && formValues["customCheckbox2"] === false && formValues["customCheckbox3"] === false) {
      setErrorMessage(getValue("error.needToCheckOneCustomCheckbox", microcopy));
    }
    else if (!checkForEmptyFields()) {
      setErrorMessage(getValue("error.needToFillInputs", microcopy));
    } else if (formValues["privacyPolicy"]) {
      setErrorMessage("");
      sendDataToSpreadSheet();
    } else {
      setErrorMessage(getValue("error.privacyPolicyCheckbox", microcopy));
    }
  };

  const handleTitle = (e) => {
    setFormValues({...formValues, title: e.value })
    setTitleValue(e)
  }
  const titleOptions = [
    {value: getValue("option.titleMr", microcopy), label: getValue("option.titleMr", microcopy)},
    {value: getValue("option.titleMrs", microcopy), label: getValue("option.titleMrs", microcopy)},
    {value: getValue("option.titleOther", microcopy), label: getValue("option.titleOther", microcopy)},
  ]

  const checkForEmptyFields = () => {
    let allFieldsFilled = true
    requiredFields.forEach(field => {
      if (formValues[field] === "") {
        allFieldsFilled = false
      }
    })
    return allFieldsFilled
  }
  const isEmailValid = (email) => {
    const emailRegex = /^(([^<>()[\]\\.#,;:\s@"]+(\.[^<>()[\]\\.#,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }
  
  return (
    <Row>
      <Col>
        {welcomeText.welcomeText && (
          <ReactMarkdown>{welcomeText.welcomeText}</ReactMarkdown>
        )}
        {formHeadline && <h2 className="headline">{formHeadline}</h2>}
        <form onSubmit={handleSubmit} noValidate>
          <div className="form-container">
            <div className="input-container">
              <label for="title">{getValue("label.title", microcopy)}*</label>
              <Select
                name="title"
                options={titleOptions}
                value={titleValue}
                onValueChange={handleTitle}
                isSearchable={false}
              />
            </div>
            <div className="input-container">
              <label for="academicTitle">
                {getValue("label.academicTitle", microcopy)}
              </label>
              <Input
                id="academicTitle"
                type="text"
                name="academicTitle"
                value={formValues["academicTitle"]}
                onChange={handleFormValues}
              />
            </div>
            <div className="input-container">
              <label for="givenName">
                {getValue("label.givenName", microcopy)}*
              </label>
              <Input
                id="givenName"
                type="text"
                name="givenName"
                value={formValues["givenName"]}
                onChange={handleFormValues}
              />
            </div>
            <div className="input-container">
              <label for="familyName">
                {getValue("label.familyName", microcopy)}*
              </label>
              <Input
                id="familyName"
                type="text"
                name="familyName"
                value={formValues["familyName"]}
                onChange={handleFormValues}
              />
            </div>
            <div className="input-container">
              <label for="emailAddress">
                {getValue("label.emailAddress", microcopy)}*
              </label>
              <Input
                id="emailAddress"
                type="email"
                name="emailAddress"
                value={formValues["emailAddress"]}
                onChange={handleFormValues}
              />
            </div>
            <div className="input-container">
              <label for="company">
                {getValue("label.company", microcopy)}*
              </label>
              <Input
                id="company"
                type="text"
                name="company"
                value={formValues["company"]}
                onChange={handleFormValues}
              />
            </div>
            <div className="input-container">
              <label for="job">{getValue("label.job", microcopy)}</label>
              <Input
                id="job"
                type="text"
                name="job"
                value={formValues["job"]}
                onChange={handleFormValues}
              />
            </div>
            <div className="input-container">
              <label for="streetAddress">
                {getValue("label.streetAddress", microcopy)}
              </label>
              <Input
                id="streetAddress"
                type="text"
                name="streetAddress"
                value={formValues["streetAddress"]}
                onChange={handleFormValues}
              />
            </div>
            <div className="input-container">
              <label for="postalCode">
                {getValue("label.postalCode", microcopy)}
              </label>
              <Input
                id="postalCode"
                type="text"
                name="postalCode"
                value={formValues["postalCode"]}
                onChange={handleFormValues}
              />
            </div>
            <div className="input-container">
              <label htmlFor="city">{getValue("label.city", microcopy)}</label>
              <Input
                id="city"
                type="text"
                name="city"
                value={formValues["city"]}
                onChange={handleFormValues}
              />
            </div>
          </div>
          <div className="custom-checkboxes-container">
            {customCheckbox1Text && (
              <Checkbox
                text={customCheckbox1Text}
                name="customCheckbox1"
                checked={formValues["customCheckbox1"]}
                onToggle={handleCheckboxes}
              />
            )}
            {customCheckbox2Text && (
              <Checkbox
                text={customCheckbox2Text}
                name="customCheckbox2"
                checked={formValues["customCheckbox2"]}
                onToggle={handleCheckboxes}
              />
            )}
            {customCheckbox3Text && (
              <Checkbox
                text={customCheckbox3Text}
                name="customCheckbox3"
                checked={formValues["customCheckbox3"]}
                onToggle={handleCheckboxes}
              />
            )}
          </div>
          <div className="submit-btn-container">
            <button className="button button--primary" type="submit" disabled={formDataSent}>
              {getValue("label.submit", microcopy)}
            </button>
            {errorMessage && (
              <small className="error-message">{errorMessage}</small>
            )}
            {successMessage && (
              <small className="success-message">{successMessage}</small>
            )}
          </div>
        </form>
        {privacyPolicyHeadline && (
          <h2 className="headline">{privacyPolicyHeadline}</h2>
        )}
        {privacyPolicyText ? (
          <ReactMarkdown>{privacyPolicyText.privacyPolicyText}</ReactMarkdown>
        ) : (
          agreeWithPrivacyPolicySublineText && (
            <p>
              {agreeWithPrivacyPolicySublineText}{" "}
              {pdfAsset?.file?.url && (
                <Link to={{ url: pdfAsset?.file?.url }} className="pdf-link">
                  {getValue("label.privacyPolicy", microcopy)}
                </Link>
              )}
            </p>
          )
        )}
       {checkboxAgreeWithPrivacyPolicy &&
          <Checkbox
            text={checkboxAgreeWithPrivacyPolicy}
            name="privacyPolicy"
            checked={formValues["privacyPolicy"]}
            onToggle={handleCheckboxes}
          />
        }
        {checkboxConsentPhotos && 
          <Checkbox
            text={checkboxConsentPhotos}
            name="consentPhotos"
            checked={formValues["consentPhotos"]}
            onToggle={handleCheckboxes}
          />
        }
        {contactHeadline && <h2 className="headline">{contactHeadline}</h2>}
        {contactText.contactText && (
          <ReactMarkdown>{contactText.contactText}</ReactMarkdown>
        )}
      </Col>
    </Row>
  );
};

EventRegistration.propTypes = {
  welcomeText: PropTypes.string,
  formHeadline: PropTypes.string,
  privacyPolicyHeadline: PropTypes.string,
  agreeWithPrivacyPolicySublineText: PropTypes.string,
  pdfAsset: PropTypes.object,
  privacyPolicyText: PropTypes.string,
  checkboxAgreeWithPrivacyPolicy: PropTypes.string,
  checkboxConsentPhotos: PropTypes.string,
  contactHeadline: PropTypes.string,
  contactText: PropTypes.string,
};
EventRegistration.defaultProps = {
  welcomeText: "",
  formHeadline: "",
  privacyPolicyHeadline: "",
  agreeWithPrivacyPolicySublineText: "",
  pdfAsset: null,
  privacyPolicyText: "",
  checkboxAgreeWithPrivacyPolicy: "",
  checkboxConsentPhotos: "",
  contactHeadline: "",
  contactText: "",
};

export default EventRegistration;
