import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql, useStaticQuery } from "gatsby";
import prettyBytes from "pretty-bytes";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { formatDate } from "../helper/date";
import { getFilteredSearchLink } from "../helper/link";
import { dtrOptions } from "../helper/richText";
import { getValue } from "../helper/translation";
import Label from "./label";
import { Contacts, Download } from "./sidebar";

const Publication = props => {
  const { generalMicrocopy } = useStaticQuery(graphql`
    {
      generalMicrocopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `);

  const microcopy = {};

  generalMicrocopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === props.node_locale) {
        microcopy[node.key] = node.value;
      }
    });

  const publication = props;

  const tags = [];
  if (publication && publication.focusOfWork) {
    tags.push(publication.focusOfWork);
  }
  if (publication && publication.topic) {
    tags.push(...publication.topic);
  }
  if (publication && publication.organizations) {
    tags.push(...publication.organizations);
  }

  return (
    <Container fluid className="publicationDetail">
      <Row>
        <Col lg={4} className="order-2">
          <Contacts
            authors={publication.author.map(author => ({
              name: author.name,
              image: author.photo &&
                author.photo.image && {
                  src: author.photo.image.file.url,
                },
              email: author.email,
              bio: author.biography && author.biography.biography,
            }))}
            locale={publication.node_locale}
          />
          {publication.document && (
            <Download
              filename={publication.title}
              link={{
                url: publication.document.file.url,
                linkText: getValue("actions.download", microcopy),
              }}
              size={prettyBytes(publication.document.file.details.size)}
              locale={publication.node_locale}
              extension={publication.document.file.contentType.split("/")[1]}
            />
          )}
        </Col>
        <Col lg={8} className="order-1">
          <h2 className="title">{publication && publication.title}</h2>
          <div className="publication-date">
            {publication &&
              formatDate(publication.publicationDateTime, publication.locale)}
          </div>
          <div className="labels">
            {tags
              .filter(item => !!item)
              .map(item => (
                <Label
                  text={item.name}
                  type="secondary"
                  className="label"
                  link={getFilteredSearchLink(
                    item.contentful_id,
                    publication.node_locale
                  )}
                />
              ))}
          </div>

          {publication &&
            documentToReactComponents(
              publication.reader.json || publication.reader,
              dtrOptions
            )}

          {publication &&
            documentToReactComponents(
              publication.content.json || publication.content,
              dtrOptions
            )}
        </Col>
      </Row>
    </Container>
  );
};

export default Publication;
