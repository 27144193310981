import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import PropTypes from 'prop-types';
import { Container } from "react-bootstrap";
import Link from "../helper/link";
import { getValue } from "../helper/translation";

const PageHeader = ({ title, headerSubtitle, link, image, node_locale, xPosition = '', yPosition = '', eventDate, placeOfEvent }) => {
  const { microcopy } = useStaticQuery(graphql`
    {
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `);

  const localizedMicrocopy = {};

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  return (
    <BackgroundImage
      Tag="section"
      className={`component pageHeader`}
      fluid={image.image.fluid}
      style={{
        backgroundPosition: `${xPosition} ${yPosition}`
      }}
    >
      <Container fluid>
        <div className="row headerContainer">
          <div className="col-12 px-0">
            <h1 className="mb-3 mb-xl-4 color-white">
              {title
                .split(".")
                .filter(sentence => sentence.length)
                .map(sentence => (
                  <span>{sentence}.</span>
                ))}
            </h1>
            {headerSubtitle && (
              <h3 className="mb-4 mb-xl-4 color-white font-weight-normal">
                {headerSubtitle}
              </h3>
            )}
            {eventDate && 
            <h3 className="color-white font-weight-normal mb-xl-2">{eventDate}</h3>
            }
            {placeOfEvent && 
            <h3 className="color-white font-weight-normal">{placeOfEvent}</h3>
            }
            {link && (
              <Link
                className="button button--inverted button--link"
                to={link.slug}
              >
                {getValue("actions.seeMore", localizedMicrocopy)}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </BackgroundImage>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  headerSubtitle: PropTypes.string,
  link: PropTypes.object,
  image: PropTypes.object,
  node_locale: PropTypes.string,
  xPosition: PropTypes.string,
  yPosition: PropTypes.string,
  eventDate: PropTypes.string,
  placeOfEvent: PropTypes.string,
};
PageHeader.defaultProps = {
  title: "",
  headerSubtitle: "",
  link: null,
  image: null,
  node_locale: "",
  xPosition: "",
  yPosition: "",
  eventDate: "",
  placeOfEvent: "",
};

export default PageHeader;
